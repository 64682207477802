import React from 'react';

export default function CreateStepContext<T extends number>({
   initialStep,
}: {
   initialStep: T;
}) {
   const stepContext = React.createContext<{
      step: T;
      updateStep: (prop: T) => void;
   }>({
      step: initialStep,
      updateStep: () => {
         return;
      },
   });

   const StepProvider = ({ children }: { children: React.ReactNode }) => {
      const [step, updateStep] = React.useState<T>(initialStep);

      return (
         <stepContext.Provider value={{ step, updateStep }}>
            {children}
         </stepContext.Provider>
      );
   };

   const useStep = () => {
      const context = React.useContext(stepContext);
      if (context === undefined) {
         throw new Error('useStep must be used within a Provider');
      }
      return context;
   };

   return {
      StepProvider,
      useStep,
   };
}
