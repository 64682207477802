import { Box, Flex } from '@chakra-ui/react';
import {
   FormsortFormTypes,
   IntakeProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';
import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';

import usePatientMedications from '@/hooks/react-query/usePatientMedications';

import { BackgroundNoisePattern } from '@/components/BackgroundNoisePattern';
import {
   MedicalIntakeStepsEnum,
   useMedicalIntakeStep,
} from '@/components/Forms/MedicalIntake/MedicalIntakeStepProvider';
import FormsortWrapper, {
   FormsortParam,
} from '@/components/Formsort/FormsortWrapper';

import { webApiClient } from '@/api-client/apiClient';
import BreakStepLayout from '@/layouts/BreakStepLayout';

interface IProps {
   // Formsort
   flowName: FormsortFormTypes;

   // Landing step
   landingStepTitle: string;
   landingStepText: string | JSX.Element;
   landingStepImage: JSX.Element;
   landingStepDesktopImage: JSX.Element;
   landingStepBtnText?: string;

   skipInitialStep?: boolean;
}

const MedicalIntakeLayout: React.FC<IProps> = ({
   flowName,
   landingStepTitle,
   landingStepText,
   landingStepImage,
   landingStepDesktopImage,
   landingStepBtnText,
   skipInitialStep,
}) => {
   const { push } = useRouter();
   const { step, updateStep } = useMedicalIntakeStep();

   useEffect(() => {
      updateStep(MedicalIntakeStepsEnum.Landing);
   }, [updateStep, flowName]);

   const handleNext = () => {
      updateStep(MedicalIntakeStepsEnum.Formsort);

      webApiClient.formsort.createForm({
         body: {
            formType: flowName,
         },
      });
   };

   const { medications } = usePatientMedications({
      enabled: flowName === FormsortFormTypes.MedicalIntakeMentalHealth,
   });

   useEffect(() => {
      if (skipInitialStep) {
         updateStep(MedicalIntakeStepsEnum.Formsort);
      }
   }, [skipInitialStep, updateStep]);

   React.useEffect(() => {
      if (flowName === FormsortFormTypes.MedicalIntakeProfile) {
         webApiClient.programs.updateProgram({
            params: {
               progName: WelkinPrograms.Intake,
            },
            body: {
               phaseName: IntakeProgramPhases.MedicalIntakeInProgress,
            },
         });
      }
   }, [flowName, push]);

   const medicationsParam = React.useMemo((): FormsortParam[] => {
      return medications
         ? [
              ['used-stimulants', String(medications.hasUsedStimulants)],
              [
                 'used-benzodiazepines',
                 String(medications.hasUsedBenzodiazepines),
              ],
           ]
         : [];
   }, [medications]);

   return (
      <>
         {step === MedicalIntakeStepsEnum.Landing && (
            <BreakStepLayout
               image={landingStepImage}
               desktopImage={landingStepDesktopImage}
               heading={landingStepTitle}
               headingSize="small"
               text={landingStepText}
               btnText={landingStepBtnText || "Ok, let's start"}
               onButtonClick={handleNext}
            />
         )}

         <Flex
            minH="var(--app-height)"
            w="100%"
            position="relative"
            marginX="auto"
            flexDir="row"
            alignItems="stretch"
            bg="background.secondary"
            p={0}
            display={step === MedicalIntakeStepsEnum.Formsort ? 'flex' : 'none'}
         >
            <BackgroundNoisePattern zIndex={1} />

            <Box flex="1" minH="full" h="full">
               <FormsortWrapper
                  flowName={flowName}
                  desktopImage={landingStepDesktopImage}
                  loadingTextColor="background.primary"
                  isLoading={
                     flowName === FormsortFormTypes.MedicalIntakeMentalHealth &&
                     !medications
                  }
                  additionalParams={medicationsParam}
               />
            </Box>
         </Flex>
      </>
   );
};

export default MedicalIntakeLayout;
