import {
   AppointmentFromBankResponseDto,
   WelkinEncounterTemplateNames,
} from '@innerwell/dtos';

import { useScheduling } from '@/contexts/scheduling-context';
import { generateSchedulingLink } from '@/utils';

import { Card } from './components';

const FOLLOWUP_TEXTS = [
   `After you’ve finished your first two sessions, check in with your Psychiatric
   Clinician to review your progress, symptoms and overall experience with ketamine.
   It’s normal to require an adjustment in your prescription at this point to a
   dose that delivers optimal healing.`,

   `After eight ketamine experiences, you’ll likely have developed some familiarity
   with the medicine and how to work with it. Checking in with your Psychiatric
   Clinician at this point in your program is an opportunity to reflect on your
   progress toward your treatment goals, and decide together whether any further
   dose adjustments are needed for your next set of experiences.`,

   `You’re now past the halfway point in your program, and you’ve undoubtedly
   learned a lot about yourself and gained some new perspectives on your life.
   We hope you’ve found a sustained, therapeutic dose of the medicine, and if not,
   your psychiatric clinician will fine tune your dose of ketamine for your final
   nine experiences.`,
];

const CAPSTONE_TEXT = `Take a moment to acknowledge the extraordinary amount of inner work you’ve
   done over the past few months. Your capstone appointment with your Psychiatric
   Clinician is not only about seeing how far you’ve come, but also deciding
   where you’d like to go from here.`;

const MEET_YOUR_THERAPIST = `During your first appointment with your therapist, you’ll review the
   reasons you’re seeking treatment, your mental health history, and
   your personal goals. Sharing details about your life with a therapist
   can feel daunting, and yet it’s one of the most powerful ways to
   process challenges and difficulties that you’ve faced. If you decide
   to work with your therapist, you’ll likely meet with them on a weekly
   basis.`;

const MEDICAL_CONSULT = `Choose your Psychiatric Clinician from our team of experts and
   schedule an appointment with them to create your personalized
   treatment plan. Your Psychiatric Clinician will be by your
   side throughout your time with Innerwell.`;

const EMDR_CONSULT = `Choose your therapist from our team of experts and schedule a consultation to
determine if Eye Movement Desensitization and Reprocessing (EMDR) is right for you.
If your therapist determines that you’re suitable to proceed with EMDR,
you’ll work together with them to create a personalized treatment plan.
EMDR is typically delivered one to two times per week for a total of 6-12 sessions.`;

const getEncounterText = (appointment: AppointmentFromBankResponseDto) => {
   if (
      appointment.templateName === WelkinEncounterTemplateNames.MedicalFollowUp
   ) {
      return FOLLOWUP_TEXTS.at(appointment.numOfEncounters);
   }

   // The old way, before the new follow-up encounters were added
   const followUpIndex = [
      WelkinEncounterTemplateNames.MedicalFollowUp,
      WelkinEncounterTemplateNames.MedicalFollowUp2,
      WelkinEncounterTemplateNames.MedicalFollowUp3,
   ].indexOf(appointment.templateName);

   if (followUpIndex > -1) {
      return FOLLOWUP_TEXTS.at(followUpIndex);
   }

   if (
      [WelkinEncounterTemplateNames.CapstoneAppointment].includes(
         appointment.templateName,
      )
   ) {
      return CAPSTONE_TEXT;
   }

   if (
      [
         WelkinEncounterTemplateNames.MedicalConsult,
         WelkinEncounterTemplateNames.MedicalConsultPsychiatry,
      ].includes(appointment.templateName)
   ) {
      return MEDICAL_CONSULT;
   }

   if (
      [
         // NOTE(AHC-246): moved psychiatry to have MEDICAL_CONSULT text
         // WelkinEncounterTemplateNames.MedicalConsultPsychiatry,
         WelkinEncounterTemplateNames.TherapistIntake50Min,
         WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
      ].includes(appointment.templateName)
   ) {
      return MEET_YOUR_THERAPIST;
   }

   if (
      [
         WelkinEncounterTemplateNames.EmdrSession,
         WelkinEncounterTemplateNames.EmdrIntake,
         WelkinEncounterTemplateNames.EmdrIntakeInsurance,
      ].includes(appointment.templateName)
   ) {
      return EMDR_CONSULT;
   }
};

const getEncounterImage = (appointment: AppointmentFromBankResponseDto) => {
   if (
      [
         WelkinEncounterTemplateNames.MedicalConsultPsychiatry,
         WelkinEncounterTemplateNames.MedicalConsult,
      ].includes(appointment.templateName)
   ) {
      return '/images/medical-consult-woman.svg';
   }

   return '/images/meet-therapist-start-kap-card.png';
};

// TODO(AHC-246): btn label should be updated. In non-ketamine plans it was "Schedule" for
// therapist and "Schedule your consult" for clinician.
const ScheduleNextAppointmentCard = ({
   appointment,
}: {
   appointment: AppointmentFromBankResponseDto;
}) => {
   const {
      online: { location },
   } = useScheduling();

   return (
      <Card>
         <Card.Image src={getEncounterImage(appointment)} alt="Card image" />
         <Card.Title>Schedule {appointment.title}</Card.Title>
         <Card.Text>{getEncounterText(appointment)}</Card.Text>
         <Card.ButtonGroup>
            <Card.ButtonLink
               href={generateSchedulingLink(appointment.templateName, location)}
            >
               Schedule now
            </Card.ButtonLink>
         </Card.ButtonGroup>
      </Card>
   );
};

export default ScheduleNextAppointmentCard;
