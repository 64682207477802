import { Box, Flex, Heading, Text, ThemingProps } from '@chakra-ui/react';
import { CustomIconButton } from '@innerwell/chakra/buttons';
import { Route } from 'next';
import React from 'react';

import { BackgroundNoisePattern } from '@/components/BackgroundNoisePattern';
import DefaultHeader from '@/components/Headers/DefaultHeader';
import { Icon } from '@/components/Icon';
import { CircleTopMask } from '@/components/Images';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import Layout from '@/layouts/Layout';

export interface IBreakStepLayoutProps {
   heading: string | JSX.Element;
   headingSize?: ThemingProps<'Heading'>['size'];
   text?: string | JSX.Element;
   image: JSX.Element;
   desktopImage?: JSX.Element;
   additionalContent?: JSX.Element;
   btnText?: string;
   btnLink?: Route;
   onButtonClick?: (props: React.MouseEvent<HTMLButtonElement>) => void;
}

const BreakStepLayout: React.FC<IBreakStepLayoutProps> = ({
   heading,
   headingSize = 'large',
   text,
   additionalContent,
   image,
   desktopImage,
   btnText = "Ok, let's continue",
   btnLink,
   onButtonClick,
}) => {
   return (
      <Layout bg="background.secondary">
         <BackgroundNoisePattern />
         {desktopImage ? (
            <Box
               display={{ base: 'none', md: 'block' }}
               position="fixed"
               right="0"
               bottom="0"
               maxW="50%"
            >
               {desktopImage}
            </Box>
         ) : null}
         <DefaultHeader />
         <Box
            zIndex={2}
            position="relative"
            display="flex"
            flexDirection="column"
            minH="calc(var(--app-height) - 76px)"
         >
            <Box
               bgColor={{ base: 'white', md: 'transparent' }}
               w="100%"
               mt="auto"
               mb={{ md: 'auto' }}
               pos="relative"
            >
               <Box position="relative" zIndex={3} display={{ md: 'none' }}>
                  {image}
               </Box>

               <Box
                  display={{ md: 'none' }}
                  pos="absolute"
                  left="0"
                  w="100%"
                  top="1px"
                  transform="translateY(-100%)"
                  zIndex={5}
               >
                  <CircleTopMask fill="white" />
               </Box>

               <Flex
                  flexDir="column"
                  px={{ base: 4, lg: 6 }}
                  pt={{ lg: 30 }}
                  pb={6}
                  pos="relative"
                  zIndex={4}
                  bg={{ base: 'white', md: 'transparent' }}
                  maxW={{ md: '590px' }}
               >
                  <Heading as="h1" size={headingSize} lineHeight={1.4}>
                     {heading}
                  </Heading>
                  {text ? (
                     <Text size="body" mt={5}>
                        {text}
                     </Text>
                  ) : null}

                  {additionalContent}

                  {btnLink || onButtonClick ? (
                     <Box mt={8} alignSelf={{ base: 'auto', md: 'flex-start' }}>
                        {btnLink ? (
                           <NextLinkButton
                              href={btnLink}
                              icon={<Icon name="chevron-right" w={5} h={5} />}
                              size="md"
                           >
                              {btnText}
                           </NextLinkButton>
                        ) : (
                           <CustomIconButton
                              icon={<Icon name="chevron-right" w={5} h={5} />}
                              onClick={onButtonClick}
                              size="md"
                           >
                              {btnText}
                           </CustomIconButton>
                        )}
                     </Box>
                  ) : null}
               </Flex>
            </Box>
         </Box>
      </Layout>
   );
};

export default BreakStepLayout;
