import { Flex, FlexProps } from '@chakra-ui/react';
import { isIOS } from 'react-device-detect';
import { NextLink } from '@/components/NextLink/NextLink';
import {
   ZOOM_APP_DOWNLOAD_LINK_ANDROID,
   ZOOM_APP_DOWNLOAD_LINK_IOS,
} from '@/utils/consts';

export const InstallZoomAppPrompt = (props: FlexProps) => {
   return (
      <Flex
         w="full"
         fontWeight={500}
         color="background.fourth"
         borderRadius="12px"
         fontSize="xxs"
         {...props}
      >
         Please install the Zoom app in advance of joining your appointment.{' '}
         <NextLink
            href={
               isIOS
                  ? ZOOM_APP_DOWNLOAD_LINK_IOS
                  : ZOOM_APP_DOWNLOAD_LINK_ANDROID
            }
         >
            Download here
         </NextLink>
         .
      </Flex>
   );
};
