import BoxedSandModal from '@/components/Modals/BoxedSandModal';
import { Button, ModalProps, Text } from '@chakra-ui/react';
import { SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS } from '@innerwell/dtos';

export const CancellationFeeDialog = ({
   isCancellingAppointment,
   onConfirm,
   ...rest
}: Omit<ModalProps, 'children'> & {
   onConfirm?: () => void;
   isCancellingAppointment: boolean;
}) => {
   return (
      <BoxedSandModal {...rest}>
         <BoxedSandModal.Heading mb={3}>
            Cancellation fee reminder
         </BoxedSandModal.Heading>
         <Text size="paragraphLarge">
            There is a $100 fee for canceling your appointment within{' '}
            {SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS} hours of
            its start time. This fee is charged out of respect for our
            clinicians’ time.
         </Text>
         <BoxedSandModal.ButtonGroup minW="full">
            <Button onClick={onConfirm} isLoading={isCancellingAppointment}>
               Continue with Cancellation
            </Button>
            <Button
               variant="outline"
               onClick={rest.onClose}
               isDisabled={isCancellingAppointment}
            >
               Close
            </Button>
         </BoxedSandModal.ButtonGroup>
      </BoxedSandModal>
   );
};
